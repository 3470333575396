<template>
  <div class="cookie-request">
    <div class="cookie-request__items">
      <atomic-image class="cookie-request__img" src="/img/cookies.png" />
      <span class="cookie-request__msg">{{ cookieRequestLocaleContent?.message }}</span>
      <button-base class="cookie-request__btn" type="primary" size="md" @click="acceptCookie">
        {{ cookieRequestLocaleContent?.buttonText }}
      </button-base>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { ICookieRequest } from '~/types';

  const { setToStorage } = useBrowserStorage();

  const { getContentData } = useContentLogic<ICookieRequest>({
    contentKey: 'cookieRequest',
    contentRoute: ['modals', 'cookie-request'],
    isPage: false,
  });

  const { data: cookieContent } = await useLazyAsyncData(getContentData);

  const cookieRequestLocaleContent = computed(
    () => cookieContent.value?.currentLocaleData ?? cookieContent.value?.defaultLocaleData
  );

  const acceptCookie = () => {
    setToStorage('acceptCookie', Date.now().toString());
  };
</script>

<style src="~/assets/styles/components/modal/cookie-request.scss" lang="scss" />
